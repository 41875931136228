export const FashionSection = () => {
    return (
        <section id={"fashion"} className={"flex flex-col min-h-[500px] px-[4%] md:px-[10.5%] py-16"}>
            <div className={"flex flex-col"}>
                <h1 className={"text-4xl md:text-5xl cinzel text-[#e45426]"}>
                    Stay Ahead In Style
                </h1>
                <p className={"cinzel text-lg md:text-xl"}>
                    Fashion is more than clothing—it's self-expression. Explore our curated suggestions for building a
                    wardrobe that reflects your personality and keeps you confident and on-trend.
                </p>
            </div>
            <div className={"relative hidden md:flex w-full mt-10"}>
                <div
                    className={"absolute bg-cover group hover:z-[1]  transition-all duration-500 flex left-0 border-[#4f4f4f]/80 rounded-full border h-40 w-[35%] items-center justify-center"}>
                    <div
                        className={"relative bg-transparent transition-all duration-500 hover:bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute opacity-0 transition-all duration-500 group-hover:opacity-100 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Everyday Fashion</p>
                    </div>
                </div>
                <div
                    className={"absolute left-[32.5%] hover:z-[1] bg-cover group transition-all duration-500 flex left-0 border-[#4f4f4f]/80 rounded-full border h-40 w-[35%] items-center justify-center"}>
                    <div
                        className={"relative bg-transparent transition-all duration-500 hover:bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute opacity-0 transition-all duration-500 group-hover:opacity-100 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/2767159/pexels-photo-2767159.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Event Styling</p>
                    </div>
                </div>
                <div
                    className={"absolute right-0     hover:z-[1] bg-cover group transition-all duration-500 flex border-[#4f4f4f]/80 rounded-full border h-40 w-[35%] items-center justify-center"}>
                    <div
                        className={"relative bg-transparent transition-all duration-500 hover:bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute opacity-0 transition-all duration-500 group-hover:opacity-100 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/450212/pexels-photo-450212.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Sustainable Fashion</p>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col gap-3 mt-10 md:hidden"}>
                <div
                    className={"bg-cover group hover:z-[1]  transition-all duration-500 flex left-0 border-[#4f4f4f]/80 rounded-full border h-40 w-full items-center justify-center"}>
                    <div
                        className={"relative transition-all duration-500 bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute transition-all duration-500 opacity-100 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Everyday Fashion</p>
                    </div>
                </div>
                <div
                    className={"    hover:z-[1] bg-cover group transition-all duration-500 flex left-0 border-[#4f4f4f]/80 rounded-full border h-40 w-full items-center justify-center"}>
                    <div
                        className={"relative transition-all duration-500 bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute transition-all duration-500 opacity-100 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/2767159/pexels-photo-2767159.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Event Styling</p>
                    </div>
                </div>
                <div
                    className={"bg-cover group transition-all duration-500 flex border-[#4f4f4f]/80 rounded-full border h-40 w-full  items-center justify-center"}>
                    <div
                        className={"relative transition-all duration-500 bg-white/60 rounded-full flex h-full w-full items-center justify-center"}>
                        <div
                            className={"absolute opacity-100 transition-all duration-500 rounded-full bg-cover z-[-1] h-full left-0 right-0 top-0 bottom-0 w-full bg-[url(https://images.pexels.com/photos/450212/pexels-photo-450212.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)]"}/>
                        <p className={"cinzel text-xl"}>Sustainable Fashion</p>
                    </div>
                </div>
            </div>
        </section>
    )
}