import './App.css';
import {LandingSection} from './sections/landing-section';
import {NavBar} from "./components/navbar";
import {AboutSection} from "./sections/abount-sections";
import {ExperienceSection} from "./sections/experience-section";
import {ReviewsSection} from "./sections/reviews-section";
import {FashionSection} from "./sections/fashion-section";
import {InteriorSection} from "./sections/interior-section";
import {CreativeSection} from "./sections/creative-section";
import {ContactSection} from "./sections/contact-section";
import {FooterSection} from "./sections/footer-section";

function App() {
    return (
        <div className="flex flex-col overflow-x-hidden min-h-screen">
            <NavBar/>
            <LandingSection/>
            <div className={"relative hidden md:flex flex-col md:h-[50vh]"}>
                <img
                    src={"https://cdn.prod.website-files.com/62df9b11d1d3f86d3dacc2e3/62dfccb810f0928ad4bdf6ee_Asterisk.svg"}
                    alt={"im"} className={"absolute top-[300px] right-[-150px] animate-spin-slow w-[300px]"}/>
            </div>
            <AboutSection/>
            <ExperienceSection/>
            <ReviewsSection/>
            <FashionSection/>
            <InteriorSection/>
            <CreativeSection/>
            <ContactSection/>
            <FooterSection/>
        </div>
    );
}

export default App;
