export const AboutSection = () => {
    return (
        <section id={"about"} className={"relative items-center overflow-hidden px-[4%] md:px-[10.5%] flex flex-col md:pt-32 min-h-screen"}>

            <div className={"flex flex-col md:items-center"}>
                <h1 className={"text-4xl md:text-6xl cinzel text-[#e45426]"}>About Us</h1>
                <p className={"text-lg md:text-xl cinzel"}>
                    Your Guide to Fashion, Interiors, and Creativity
                </p>
            </div>
            <div className={"flex gap-2 items-center h-full md:text-center mt-4 md:mt-8"}>
                <div className={"flex flex-col justify-center md:items-center w-full"}>
                    <p className={"cinzel text-sm"}>
                        I am passionate about helping you make the best choices in fashion, interior design, and
                        creative endeavors. I bring years of experience to deliver actionable suggestions, inspiring
                        ideas, and curated trends tailored to your lifestyle. <br/><br/>
                        Whether you're revamping your wardrobe, redesigning your home, or seeking artistic inspiration,
                        my mission is to make design accessible, enjoyable, and transformative.
                    </p>
                </div>
            </div>
            <div className={"flex md:flex-row flex-col w-full mt-8 md:mt-32"}>
                <div className={"flex w-full flex-col"}>
                    <div className={"flex flex-col md:pr-16"}>
                        <h1 className={"text-2xl md:text-4xl cinzel text-[#e45426]"}>Why Choose Me ?</h1>
                        <p className={"text-md cinzel"}>
                            Your Guide to Fashion, Interiors, and Creativity
                        </p>
                        <img
                            src={"https://images.pexels.com/photos/669502/pexels-photo-669502.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                            alt={"user"}
                            className={"my-8 md:mt-8 w-full h-[300px] object-cover rounded-[50px]"}
                        />
                    </div>
                </div>
                <div className={"w-full flex flex-col"}>
                    <div className={"flex flex-col border-t border-[#afafaf] py-10"}>
                        <p className={"cinzel text-2xl"}>
                            Expertise across multiple design fields
                        </p>
                        <p className={"text-md text-[#8f8f8f]"}>
                            I bring a versatile background in fashion styling, interior aesthetics, and creative arts,
                            ensuring well-rounded advice for all your design needs.
                        </p>
                    </div>
                    <div className={"flex flex-col border-t border-[#afafaf] py-10"}>
                        <p className={"cinzel text-2xl"}>
                            Trend-driven insights combined with timeless approaches
                        </p>
                        <p className={"text-md text-[#8f8f8f]"}>
                            My recommendations strike the perfect balance between staying on-trend and embracing
                            timeless elegance, ensuring your choices remain relevant for years.
                        </p>
                    </div>
                    <div className={"flex flex-col border-y border-[#afafaf] py-10"}>
                        <p className={"cinzel text-2xl"}>
                            Commitment to sustainability and ethical practices
                        </p>
                        <p className={"text-md text-[#8f8f8f]"}>
                            I prioritize eco-conscious solutions and ethically sourced materials, helping you design
                            beautifully while making a positive impact on the world.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}