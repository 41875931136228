import ellipse from "../assets/landing-ellipse.svg";
import asterisk from "../assets/Asterisk Sharp.svg";

export const LandingSection = () => {
    return (
        <section id={"home"}
                 className={"flex flex-col pt-[10.25rem] px-[4%] md:px-0 w-full md:items-center md:justify-center pb-[6.25rem]"}>
            <div
                className={"relative flex flex-col justify-center max-w-[1280px] px-[2.5rem] w-full"}
            >
                <div className={"relative hidden md:flex flex-col w-full h-full justify-center items-center"}>
                    <div className={"guides h-full"}>
                        <div
                            className={"absolute col-span-1 row-span-1 left-[38rem] top-[-7.5px] w-[5rem] h-[5rem] col-start-1 row-start-1 row-end-1"}>
                            <img src={asterisk} className={"animate-spin-slow"} alt=""/>
                        </div>
                        <h1 className={"w-full col-span-3 row-span-1 text-[#4f4f4f] col-start-1 cinzel row-start-1 row-end-1 text-7xl"}>
                            Inspiration for <br/>
                            <span className={"text-[#e45426] whitespace-nowrap ml-[24.3%]"}>
                            Style, Spaces,&nbsp;
                        </span>
                            and <br/>
                            <span className={"text-[#e45426] ml-[12.6%]"}>
                            &nbsp; Creativity
                        </span>
                        </h1>
                        <div
                            className={"absolute left-[3.67%] top-[120px] w-[32.5%] filter z-[-1] object-fit rounded-t-full col-start-1 row-start-1 row-end-1"}>
                            <div>
                                <img
                                    src={"https://i.pinimg.com/736x/e6/1e/5f/e61e5fbe37cfe067ca06b9a37d668d37.jpg"}
                                    alt={"user"}
                                    className={"rounded-t-full h-[500px] w-[400px] object-cover"}
                                />
                            </div>
                        </div>
                        <div className={"col-start-1 row-start-2 row-end-2"}>
                            <p className={"absolute ml-[34%] max-w-[48ch] mt-[2.5rem] mb-[14.75rem] text-[#8f8f8f] text-sm "}>
                                I am Farheen Hussain, your ultimate guide for enhancing your lifestyle with the perfect
                                blend of fashion, interior design, and creative artistry. I am passionate about
                                providing
                                expert insights, practical solutions, and innovative ideas that resonate with your style
                                and
                                personality.
                            </p>
                        </div>
                        <div
                            className={"absolute col-start-3 top-[45vh] cinzel col-end-4 row-start-3 row-end-3 flex gap-[3.05vw]"}>
                            <a href={"mailto:farheenhusaain.21@gmail.com"}
                               className={"group text-sm transition duration-300 text-[#333] hover:text-[#e45629]"}>
                                Email
                                <div
                                    className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                    <div className={"underline-calm w-[100%] h-full"}/>
                                </div>
                            </a>
                            <a href={"tel:+919834158841"}
                               className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                                Phone
                                <div
                                    className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                    <div className={"underline-calm w-[100%] h-full"}/>
                                </div>
                            </a>
                            <a href={"https://maps.app.goo.gl/KpxkNiwxu21ahYEK7"}
                               className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                                Address
                                <div
                                    className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                    <div className={"underline-calm w-[100%] h-full"}/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"md:hidden flex flex-col gap-3"}>
                <h1 className={"w-full col-span-3 row-span-1 text-[#4f4f4f] col-start-1 cinzel row-start-1 row-end-1 text-5xl"}>
                    Inspiration <br/> for
                    <span className={"text-[#e45426]"}>
                            &nbsp;Style, Spaces,&nbsp;
                        </span>
                    and <br/>
                    <span className={"text-[#e45426]"}>
                            Creativity
                    </span>
                </h1>
                <div className={"translate-y-[-2.5rem] z-[-1]"}>
                    <img
                        src={"https://i.pinimg.com/736x/e6/1e/5f/e61e5fbe37cfe067ca06b9a37d668d37.jpg"}
                        alt={"user"}
                        className={"rounded-t-full h-[500px] w-[400px]"}
                    />
                </div>
                <p className={"text-sm translate-y-[-2.5rem]"}>
                    I am Farheen Hussain, your ultimate guide for enhancing your lifestyle with the perfect
                    blend of fashion, interior design, and creative artistry. I am passionate about
                    providing
                    expert insights, practical solutions, and innovative ideas that resonate with your style
                    and
                    personality.
                </p>
                <div
                    className={"translate-y-[-1.5rem] top-[45vh] cinzel items-center justify-center flex gap-[3.05vw]"}>
                    <a href={"mailto:farheenhusaain.21@gmail.com"}
                       className={"group text-sm transition duration-300 text-[#333] hover:text-[#e45629]"}>
                        Email
                        <div
                            className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </a>
                    <a href={"tel:+919834158841"}
                       className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                        Phone
                        <div
                            className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </a>
                    <a href={"https://maps.app.goo.gl/KpxkNiwxu21ahYEK7"}
                       className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                        Address
                        <div
                            className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </a>
                </div>
            </div>
        </section>);
};
