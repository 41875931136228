export const ContactSection = () => {
    return (
        <section id={"contact"} className={"flex flex-col overflow-hidden px-[4%] md:px-[10.5%] py-16"}>
            <div className={"flex flex-col md:flex-row justify-between gap-10"}>
                <div className={"flex flex-col w-full gap-4"}>
                    <div className={"flex gap-4 md:gap-10 items-center"}>
                        <h1 className={"text-4xl md:text-7xl cinzel"}>
                            Have
                        </h1>
                        <img
                            src={"https://cdn.prod.website-files.com/62df9b11d1d3f86d3dacc2e3/62df9b6f0d2424466ab73d69_Logo.svg"}
                            alt={"logo"}
                            className={"w-10 h-10 md:w-20 md:h-20 animate-spin-slow"}
                        />
                    </div>
                    <h1 className={"text-4xl md:text-7xl cinzel"}>
                        Questions?
                    </h1>
                    <h1 className={"text-4xl md:text-7xl cinzel text-[#e45426]"}>
                        Let's Connect.
                    </h1>
                </div>
                <div className={"hidden md:block w-1 h-36 self-center bg-[#e45426]/20"}></div>
                <div className={"w-full flex flex-col gap-3 mt-4 md:mt-8"}>
                    <div className={"flex flex-col gap-1"}>
                        <input placeholder={"Full Name"}
                               className={"outline-none focus:bg-[#e45426]/5 p-3 cinzel bg-transparent border-[#e45426] border-y border-1"}
                        />
                        <input placeholder={"Email"}
                               className={"outline-none focus:bg-[#e45426]/5 p-3 cinzel bg-transparent border-[#e45426] border-b border-1"}
                        />
                        <input placeholder={"Subject"}
                               className={"outline-none focus:bg-[#e45426]/5 p-3 cinzel bg-transparent border-[#e45426] border-b border-1"}
                        />
                        <input placeholder={"Message"}
                               className={"outline-none focus:bg-[#e45426]/5 p-3 cinzel bg-transparent border-[#e45426] border-b border-1"}
                        />
                        <div className={"flex justify-end mt-4"}>
                            <button className={"w-[150px] transition-all bg-transparent hover:bg-[#e45426] hover:text-white py-2 border border-[#e45426] cinzel"}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex md:flex-row flex-col gap-3 mt-8 w-full justify-evenly md:items-center"}>
                <div className={"flex flex-col"}>
                    <p className={"text-[#8f8f8f] text-sm"}>Phone No.</p>
                    <a href={"tel:+919834158841"}
                       className={"group whitespace-nowrap w-min text-xl transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                        +91 9834158841
                        <div
                            className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </a>
                </div>
                <div className={"flex flex-col "}>
                    <p className={"text-[#8f8f8f] text-sm"}>Email</p>
                    <a href={"mailto:farheenhusaain.21@gmail.com"}
                       className={"group whitespace-nowrap w-min text-xl transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                        farheenhusaain.21@gmail.com
                        <div
                            className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </a>
                </div>
                <div className={"flex flex-col "}>
                    <p className={"text-[#8f8f8f] text-sm"}>Address</p>
                    <p
                       className={"group whitespace-nowrap w-min text-xl transition-all duration-300 text-[#333]"}>
                        302, Raj Nagar, Nagpur, MH, India - 440013
                        <div
                            className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%]"}>
                            <div className={"underline-calm w-[100%] h-full"}/>
                        </div>
                    </p>
                </div>
            </div>
        </section>
    )
}