export const FooterSection = () => {
    const menu = [
        {
            label: "Home",
            link: "/"
        },
        {
            label: "About",
            link: "/#about"
        },
        {
            label: "Experience",
            link: "/#experience"
        },
        {
            label: "Fashion",
            link: "/#fashion"
        },
        {
            label: "Contacts",
            link: "/#contact"
        },
    ]

    return (
        <footer className={"border-t flex-col gap-10 md:gap-0 md:flex-row md:items-center justify-between flex border-[#afafaf]/50 mx-[4%] md:mx-[10.5%] py-16"}>
            <div className={"w-[200px]"}>
                <img
                    src={"https://cdn.prod.website-files.com/62df9b11d1d3f86d3dacc2e3/62df9b6f0d2424466ab73d69_Logo.svg"}
                    className={"hover:rotate-[360deg] transition duration-500"}
                    alt={"logo"}/>
            </div>
            <div className={"flex flex-wrap md:flex-row gap-10"}>
                {
                    menu.map((item, _)=>(
                        <a key={_} href={item.link} className={"text-sm hover:text-[#e45426]"}>
                            {item.label}
                        </a>
                    ))
                }
            </div>
            <p className={"text-sm w-[200px] text-center self-center md:text-right"}>
                © 2024 All rights reserved. <br/>
                CoPower Technologies
            </p>
        </footer>
    )
}