import {IoClose, IoMenu} from "react-icons/io5";
import {useEffect, useState} from "react";
import {BiMenu} from "react-icons/bi";
import {HiMenu} from "react-icons/hi";
import {LuMenu} from "react-icons/lu";

export const NavBar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
        window.addEventListener("keydown", (e) => {
            console.log(e.key);
            if (openMenu) {
                if (e.key === "Escape") {
                    setOpenMenu(false);
                }
            }
        })
        if (openMenu) {
            const body = document.querySelector("body");
            body.style.overflow = "hidden";
        } else {
            const body = document.querySelector("body");
            body.style.overflow = "auto";
        }
    });

    const menu = [
        {
            label: "Home",
            link: "/"
        },
        {
            label: "About",
            link: "/#about"
        },
        {
            label: "Experience",
            link: "/#experience"
        },
        {
            label: "Fashion",
            link: "/#fashion"
        },
        {
            label: "Contacts",
            link: "/#contact"
        },
    ]

    return (
        <div
            className={`fixed flex justify-between z-[999] left-0 top-0 right-0 w-full ${scrolled ? "border-b border-[#4f4f4f]/20" : "border-0"}`}>
            <div
                className={`flex w-full ${scrolled || openMenu ? "bg-[#efefef]" : "bg-transparent"} py-12 px-[4%] md:px-[10.5%] justify-between`}>
                <div className={"flex gap-3 items-center"}>
                    <img
                        src={"https://cdn.prod.website-files.com/62df9b11d1d3f86d3dacc2e3/62df9b6f0d2424466ab73d69_Logo.svg"}
                        className={"hover:rotate-[360deg] transition duration-500"}
                        alt={"logo"}/>
                    <h1 className={"cinzel text-xl whitespace-nowrap"}>
                        FARHEEN HUSSAIN
                    </h1>
                </div>
                <div className={"flex cursor-pointer flex-col h-10 w-10 text-[#4f4f4f] gap-3"} onClick={() => {
                    setOpenMenu(!openMenu);
                }}>
                    <div
                        className={`h-1 bg-[#4f4f4f] transition-all rounded ${openMenu ? "rotate-45 translate-y-4" : "rotate-0"}`}/>
                    <div
                        className={`h-1 bg-[#4f4f4f] transition-all rounded ${openMenu ? "rotate-[-45deg] translate-y-[-0rem]" : "rotate-0"}`}/>
                </div>
            </div>
            <div
                className={`absolute bg-[#ededed] flex shadow-2xl top-[135px] overflow-hidden transition-all duration-500 left-0 right-0 ${openMenu ? "md:h-[82vh] h-[84vh]" : "h-[0vh]"} ${openMenu ? "opacity-100" : "opacity-0"}`}>
                <div className={'flex pl-[4%] md:pl-[10.5%] flex-col w-full md:w-[60%] h-full'}>
                    <div className={"flex justify-between"}>
                        <div
                            className={"flex flex-col gap-10 h-[81vh] pb-20 overflow-x-hidden overflow-y-scroll w-full"}>
                            {
                                menu.map((item, _) => (
                                    <a href={item.link}
                                       onClick={() => {
                                           setOpenMenu(false);
                                       }}
                                       style={{
                                           transitionDelay: !openMenu
                                               ? `${(menu.length - _ - 1) * 100}ms`
                                               : `${_ * 100}ms`
                                       }}
                                       className={`group cinzel w-min text-4xl md:text-8xl transition-all duration-300 text-[#333] hover:text-[#e45629]
                                       ${openMenu ? "translate-y-0" : "translate-y-20"}`}>
                                        <div className={"flex gap-10"}>
                                            {item.label}
                                            <span className={"text-sm"}>
                                        0{_ + 1}
                                    </span>
                                        </div>
                                        <div
                                            className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                            <div className={"underline-large w-[100%] h-full"}/>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>

                    </div>
                </div>
                <div className={"hidden md:flex flex-col w-[40%] justify-between p-20"}>
                    <div className={"flex flex-col w-full"}>
                        <p className={"text-[#8f8f8f]"}>Phone No.</p>
                        <a href={"/"}
                           className={"group whitespace-nowrap w-min text-2xl transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                            +91 9834158841
                            <div
                                className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                    </div>
                    <div className={"flex flex-col w-full"}>
                        <p className={"text-[#8f8f8f]"}>Email</p>
                        <a href={"/"}
                           className={"group whitespace-nowrap w-min text-2xl transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                            farheenhusaain.21@gmail.com
                            <div
                                className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                    </div>
                    <div className={"flex flex-col w-full"}>
                        <p className={"text-[#8f8f8f]"}>Address</p>
                        <a href={"/"}
                           className={"group whitespace-nowrap w-min text-2xl transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                            302, Raj Nagar, Nagpur, MH, India - 440013
                            <div
                                className={"relative transition-all duration-300 h-[20.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                    </div>
                    <div
                        className={"col-start-3 top-[45vh] cinzel col-end-4 row-start-3 row-end-3 flex gap-[3.05vw]"}>
                        <a href={"mailto:farheenhusaain.21@gmail.com"}
                           className={"group text-sm transition duration-300 text-[#333] hover:text-[#e45629]"}>
                            Email
                            <div
                                className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                        <a href={"tel:+919834158841"}
                           className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                            Phone
                            <div
                                className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                        <a href={"https://maps.app.goo.gl/KpxkNiwxu21ahYEK7"}
                           className={"group text-sm transition-all duration-300 text-[#333] hover:text-[#e45629]"}>
                            Address
                            <div
                                className={"relative transition-all duration-300 h-[18.75%] overflow-hidden w-[0%] group-hover:w-[100%]"}>
                                <div className={"underline-calm w-[100%] h-full"}/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}