import asterisk from "../assets/Asterisk Sharp.svg";


export const ReviewsSection = () => {
    return (
        <section className={"flex flex-col py-20"}>
            <div
                className={"flex justify-between w-full items-center px-[4%] md:px-[10.5%]"}>
                <h1 className={"cinzel text-4xl md:text-7xl text-[#e45426]"}>
                    Customers <br/> Testimonials
                </h1>
                <img src={asterisk} className={"md:block hidden animate-spin-slow h-52"} alt=""/>
            </div>
            <div className={"hidden md:flex justify-between w-full mt-20"}>
                <div className={"flex flex-col"}>
                    <div className={"flex gap-10 w-full"}>
                        <div className={"underline-large h-5 w-[80%] mr-20"}/>
                        <div className={"w-[20%]"}/>
                    </div>
                    <div className={"flex flex-col w-[80%] pl-40 mt-52"}>
                        <h1 className={"cinzel text-4xl"}>
                            "OUR OFFICE REDESIGN HAS BOOSTED PRODUCTIVITY AND CREATED A SPACE WE LOVE.”
                        </h1>
                        <div className={"flex justify-between mt-4"}>
                            <div className={"flex flex-col mt-4"}>
                                <h1 className={"cinzel text-xl"}>
                                    -Lisa T
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col w-[80%] pr-[10.5%]"}>
                    <h1 className={"cinzel text-4xl"}>
                        "I’M USUALLY SOMEONE WHO STRUGGLES TO FIND THE BALANCE BETWEEN FUNCTIONALITY AND STYLE, BUT FARHEEN BREAKS IT DOWN SO EFFORTLESSLY.”
                    </h1>
                    <div className={"flex justify-between mt-4"}>
                        <div className={"flex flex-col mt-4"}>
                            <h1 className={"cinzel text-xl"}>
                                -Sarah
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"md:hidden flex flex-col px-[4%] mt-10"}>
                <div className={"flex flex-col w-full py-5 border-y border-[#4f4f4f]/60"}>
                    <h1 className={"cinzel text-xl md:text-4xl"}>
                        "OUR OFFICE REDESIGN HAS BOOSTED PRODUCTIVITY AND CREATED A SPACE WE LOVE”
                    </h1>
                    <div className={"flex gap-5 mt-4"}>
                        <div className={"flex flex-col self-end mb-4"}>
                            <h1 className={"cinzel text-xl"}>
                                -Lisa T
                            </h1>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col w-full py-5 border-b border-[#4f4f4f]/60"}>
                    <h1 className={"cinzel text-xl md:text-4xl"}>
                        "I’M USUALLY SOMEONE WHO STRUGGLES TO FIND THE BALANCE BETWEEN FUNCTIONALITY AND STYLE, BUT FARHEEN BREAKS IT DOWN SO EFFORTLESSLY.”
                    </h1>
                    <div className={"flex gap-5 mt-4"}>
                        <div className={"flex flex-col self-end mb-4"}>
                            <h1 className={"cinzel text-xl"}>
                                -Sarah
                            </h1>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}