import useEmblaCarousel from "embla-carousel-react";

export const ExperienceSection = () => {
    const [emblaRef, emblaApi] = useEmblaCarousel({});

    return (
        <section id={"experience"} className={"flex px-[4%] md:px-[10.5%] flex-col min-h-screen pt-20"}>
            <div className={"flex flex-col gap-3"}>
                <h1 className={"text-4xl md:text-6xl text-[#e45426] cinzel"}>
                    Our Journey:<br/> Experience That Speaks Volumes
                </h1>
                <p className={"cinzel text-lg    md:text-2xl"}>
                    With 2 years of professional experience, I have worked with clients across diverse industries,
                    delivering impactful results in:
                </p>
            </div>
            <div className={"flex md:flex-row flex-col gap-8 w-full mt-10"}>
                <div
                    className="flex group transition-all h-[500px] rounded-t-full flex-col md:w-1/3
                    bg-[url(https://i.pinimg.com/736x/96/1f/00/961f0078ccf4b3cacfbbbc183a4cfff8.jpg)]">
                    <div
                        className={"group-hover:backdrop-blur-xl duration-500 transition-all gap-3 flex flex-col bg-gradient-to-t rounded-t-full from-[#94a4cb]" +
                            " to-[#94a4cb]/0 group-hover:to-[#94a4cb]/40 h-full w-full justify-center items-center text-center pt-20 px-10"}>
                        <h1 className={"text-2xl text-white cinzel"}>
                            Fashion Experience
                        </h1>
                        <p className={"text-sm cinzel text-white"}>
                            Collaborated with renowned brands to curate fashion collections.
                        </p>
                    </div>
                </div>
                <div
                    className="flex group h-[500px] rounded-t-full flex-col md:w-1/3
                    bg-[url(https://i.pinimg.com/736x/f2/d6/8e/f2d68eee3bc1f2490a8d2a477046adbb.jpg)]">
                    <div
                        className={"group-hover:backdrop-blur-xl duration-500 transition-all flex flex-col bg-gradient-to-t rounded-t-full from-[#745f39]" +
                            " to-[#745f39]/0 group-hover:to-[#745f39]/20 h-full w-full justify-center items-center text-center pt-20 px-10 gap-3"}>
                        <h1 className={"text-2xl text-white cinzel"}>
                            Interior Design Experience
                        </h1>
                        <p className={"text-sm cinzel text-white"}>
                            Recognized for creating sustainable and functional living spaces.
                            Partnered with top suppliers and artisans to deliver quality craftsmanship.
                        </p>
                    </div>
                </div>
                <div
                    className="flex group h-[500px] rounded-t-full flex-col md:w-1/3
                    bg-[url(https://i.pinimg.com/736x/24/d8/a8/24d8a8e2f0c5a3e36d61f4e83871dbd8.jpg)]">
                    <div
                        className={"group-hover:backdrop-blur-xl duration-500 transition-all flex flex-col bg-gradient-to-t rounded-t-full from-[#081d3a]" +
                            " to-[#081d3a]/0 group-hover:to-[#081d3a]/20 h-full w-full justify-center items-center text-center pt-20 px-10 gap-3"}>
                        <h1 className={"text-2xl text-white cinzel"}>
                            Creative Design Experience
                        </h1>
                        <p className={"text-sm cinzel text-white"}>
                            Created custom art installations and conceptual designs for events and campaigns.
                            Delivered workshops and seminars on creative thinking and design.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}